import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import '/src/util/rem'
import '/src/util/reset.css'
import BasicRouter from '/src/route/index'
import store from '/src/store/index'
import { ThemeContext } from '/src/util/context'
import 'antd/dist/antd.css';


const App = () => {
    const [theme, setTheme] = useState({
        bg: 'blue', subTitle: '#056CBD', subContext: '#1C42AA', fontColor: 'white',
        color: 'linear-gradient(#081143 5%, #043193 10%, #032EA1 20%)'
    })

    useEffect(() => {
        if(store.getState().theme){
            store.subscribe(() => {
                setTheme(store.getState().theme)
            })
        }

        if (JSON.parse(localStorage.getItem('theme'))) {
            store.dispatch({
                type: 'theme',
                value: JSON.parse(localStorage.getItem('theme'))
            })
        }
    }, [])

    useEffect(() => {
        // console.log('主题色 >>> ', theme)
    }, [theme])

    return (
        <ThemeContext.Provider value={theme}>
            <BasicRouter />
        </ThemeContext.Provider>
    )
}

const root = document.getElementById('root')
ReactDOM.render(
    <App />, root
)