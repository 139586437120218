/*
 * @Descripttion: 访问量
 * @Author: Montana
 * @Date: 2021-07-30 13:37:19
 * @LastEditTime: 2021-08-13 13:44:44
 */

import React, { useEffect, useState, useRef } from 'react'
import './index.scss'
import { Modal, Button, Form, Input, InputNumber, message } from 'antd';
import { getVisitor, setVisitor } from '/src/util/api'
import { digitalComma, percent } from '/src/util/tool'
import { ThemeContext } from '/src/util/context'

const Visit = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [obj, setObj] = useState({})
    const [flag, setFlag] = useState(false)
    const [params, setParams] = useState({})
    const [allow, setAllow] = useState(false)

    useEffect(() => {
        _getVisitor()
    }, [])

    useEffect(() => {
        setFlag(true)
    }, [obj])

    useEffect(() => {
        if (params.target && params.password) {
            setAllow(true)
        } else {
            setAllow(false)
        }
    }, [params])

    // 清理缓存
    const _clearData = () => {
        formRef && formRef.current.setFieldsValue({
            "target": '',
            "password": ''
        })
    }

    // 获取访问量
    const _getVisitor = async () => {
        const data = await getVisitor()
        if (data.success) {
            setObj(data.result)
        }
    }

    // 设置访问量
    const _setVisitor = async () => {
        if (allow) {
            let _params = {
                target: params.target
            }
            const data = await setVisitor(_params)
            if (data.success) {
                message.success('操作成功！')
                _getVisitor()
            }
            setIsModalVisible(false);
        }
    }

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        formRef.current.submit()
        if (params.password == 'shbd') {
            _setVisitor()
        } else {
            if (allow) {
                message.error('密码错误')
            }
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };
    const formRef = useRef()
    return (
        <ThemeContext.Consumer>
            {theme => {
                return <div className="visit-component">
                    <Modal title="修改目标" okText='确定' cancelText='取消' okType={allow ? 'primary' : 'ghost'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                        <Form
                            ref={formRef}
                            name="basic"
                            labelCol={{ span: 6 }}
                            wrapperCol={{ span: 16 }}
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            onValuesChange={(changeVal, allVal) => {
                                setParams(allVal)
                            }}
                        >
                            <Form.Item
                                label="访问量目标"
                                name="target"
                                rules={[{ required: true, message: '请输入访问量目标!' }]}
                            >
                                <InputNumber maxLength={8} style={{ width: '100%' }} />
                            </Form.Item>

                            <Form.Item
                                label="操作密码"
                                name="password"
                                rules={[{ required: true, message: '请输入操作密码!' }]}
                            >
                                <Input.Password />
                            </Form.Item>
                        </Form>
                    </Modal>
                    <header style={{ background: theme.subTitle , color: theme.fontColor}}>
                        <span>访问量</span>
                        <span className='icon-layout' onClick={showModal}>
                            <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-shezhi"></use>
                            </svg>
                        </span>
                    </header>
                    {flag && <main style={{background:theme.subContext, color: theme.fontColor}}>
                        <div className='col-1'>
                            <span>{obj.allPv && digitalComma(obj.allPv)}</span>
                            <span>目标: {obj.target && digitalComma(obj.target)}</span>
                        </div>
                        <div className='col-2'>
                            <div>
                                <span className="col-2-label">月同比</span>
                                <span style={{ margin: '0 0.04rem' }}>
                                    <svg className="icon" aria-hidden="true">
                                        <use xlinkHref="#icon-sanjiaotop"></use>
                                    </svg>
                                </span>
                                <span>{percent(obj.csm)}%</span>
                            </div>
                            <div>
                                <span className="col-2-label">日环比</span>
                                <span style={{ margin: '0 0.04rem' }}>
                                    <svg className="icon" aria-hidden="true">
                                        <use xlinkHref="#icon-sanjiaoxia"></use>
                                    </svg>
                                </span>
                                <span>{percent(obj.dom)}%</span>
                            </div>
                        </div>
                        <div className='col-3'>
                            <span>日均访问量</span>
                            <span style={{ marginLeft: "0.1rem" }}>{digitalComma(obj.da)}</span>
                        </div>
                    </main>}
                </div>
            }}
        </ThemeContext.Consumer>
    )
}

export default Visit