/*
 * @Descripttion:
 * @Author: Montana
 * @Date: 2021-08-02 14:49:06
 * @LastEditTime: 2021-08-09 13:00:33
 */
import React, { useEffect, useState } from "react";
import {
  CaretUpOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import store from "/src/store/index";
import * as Pinyin from "wd-hanzi2pinyin";
import { ThemeContext } from "/src/util/context";

import "./index.scss";
import { message } from "antd";

const HeadPark = () => {
  const [date, setDate] = useState("");
  const [cityCode, setCity] = useState("");

  // 获取当前时间
  const getNowTime = () => {
    let dateTime;
    let yy = new Date().getFullYear();
    let mm =
      new Date().getMonth() + 1 < 10
        ? "0" + (new Date().getMonth() + 1)
        : new Date().getMonth() + 1;
    let dd =
      new Date().getDate() < 10
        ? "0" + new Date().getDate()
        : new Date().getDate();
    let hh =
      new Date().getHours() < 10
        ? "0" + new Date().getHours()
        : new Date().getHours();
    let mf =
      new Date().getMinutes() < 10
        ? "0" + new Date().getMinutes()
        : new Date().getMinutes();
    let ss =
      new Date().getSeconds() < 10
        ? "0" + new Date().getSeconds()
        : new Date().getSeconds();
    dateTime = yy + "-" + mm + "-" + dd + " " + hh + ":" + mf + ":" + ss;
    return dateTime;
  };

  useEffect(() => {
    let city = localStorage.getItem("city");
    setCity(Pinyin.convertToPinyin(city).toLowerCase());
    let timer = setInterval(() => {
      setDate(getNowTime());
    }, 1000);
    // timer()
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div
        style={{
          position: "relative",
          height: "100%",
          display: "flex",
          alignItems: "center",
        }}
      >
        <span style={{ marginBottom: "0" }}>{date}</span>
        <div
          style={{
            position: "absolute",
            height: "100%",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 999,
          }}
        ></div>
        {/* <iframe width="140px" height="25" frameBorder="0" scrolling="no" hspace="0" src="https://i.tianqi.com/?c=code&id=1&color=%23FFFFFF"></iframe> */}
        {/* <iframe
          width="200"
          height="24"
          frameBorder="0"
          scrolling="no"
          hspace="0"
          src={
            "https://i.tianqi.com/?c=code&id=11&color=%23FFFFFF&py=" + cityCode
          }
        ></iframe> */}
      </div>
    </>
  );
};

const Common = (props) => {
  const [skinInx, setSkinInx] = useState(0);
  const [visible, setVisible] = useState(false);
  const [imgUrl, setImgUrl] = useState("");

  const list = [
    {
      text: "科技蓝",
      textColor: "white",
      bg: "#032EA1",
    },
    {
      text: "深沉黑",
      textColor: "white",
      bg: "#06142F",
    },
    {
      text: "传统白",
      textColor: "black",
      bg: "#F2F8F3",
    },
    {
      text: "活力绿",
      textColor: "white",
      bg: "#07BEB8",
    },
    {
      text: "神秘紫",
      textColor: "white",
      bg: "#36213E",
    },
  ];

  useEffect(() => {
    initSkinInx();
  }, []);

  useEffect(() => {
    let action;
    switch (skinInx) {
      case 0:
        action = {
          type: "theme",
          value: {
            bg: "blue",
            subTitle: "#056CBD",
            subContext: "#1C42AA",
            fontColor: "white",
            color: "linear-gradient(#081143 5%, #043193 10%, #032EA1 20%)",
          },
        };
        break;
      case 1:
        action = {
          type: "theme",
          value: {
            bg: "black",
            subTitle: "#165F5F",
            subContext: "#182F33",
            fontColor: "white",
            color: "linear-gradient(#000000 5%, #192222 10%, #1B1B1B 20%)",
          },
        };
        break;
      case 2:
        action = {
          type: "theme",
          value: {
            bg: "white",
            subTitle: "#FFFFFF",
            subContext: "#FFFFFF",
            fontColor: "black",
            color: "linear-gradient(#000000 5%, #DFE7E7 12%)",
          },
        };
        break;
      case 3:
        action = {
          type: "theme",
          value: {
            bg: "green",
            subTitle: "#07BEB8",
            subContext: "#3DCCC7",
            fontColor: "white",
            color: "linear-gradient(#0C4949 5%, #97E4E1 12%)",
          },
        };
        break;
      case 4:
        action = {
          type: "theme",
          value: {
            bg: "purple",
            subTitle: "#5E548E",
            subContext: "#49406F",
            fontColor: "white",
            color: "linear-gradient(#201A30 5%, #2F2849 12%)",
          },
        };
        break;
      default:
        action = {
          type: "theme",
          value: {
            bg: "blue",
            subTitle: "#056CBD",
            subContext: "#1C42AA",
            fontColor: "white",
            color: "linear-gradient(#081143 5%, #043193 10%, #032EA1 20%)",
          },
        };
        break;
    }
    store.dispatch(action);
  }, [skinInx]);

  // 设置背景
  const setTheme = (theme) => {
    setVisible(false);
    localStorage.setItem("theme", JSON.stringify(theme));
    message.success("操作成功");
  };

  // 预览
  const _preview = (e, inx) => {
    // 阻止冒泡
    e.stopPropagation();
    switch (inx) {
      case 0:
        setImgUrl(require("../static/theme/blue.png"));
        break;
      case 1:
        setImgUrl(require("../static/theme/black.png"));
        break;
      case 2:
        setImgUrl(require("../static/theme/white.png"));
        break;
      case 3:
        setImgUrl(require("../static/theme/green.png"));
        break;
      case 4:
        setImgUrl(require("../static/theme/purple.png"));
        break;
      default:
        setImgUrl(require("../static/theme/blue.png"));
    }
  };

  // 设置皮肤索引
  const initSkinInx = () => {
    if (localStorage.getItem("theme")) {
      switch (JSON.parse(localStorage.getItem("theme")).bg) {
        case "blue":
          setSkinInx(0);
          break;
        case "black":
          setSkinInx(1);
          break;
        case "white":
          setSkinInx(2);
          break;
        case "green":
          setSkinInx(3);
          break;
        case "purple":
          setSkinInx(4);
          break;
        default:
          setSkinInx(0);
      }
    }
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => {
        return (
          <div
            id="layout"
            style={{
              background: theme.color,
            }}
          >
            {/* 预览图片 */}
            {imgUrl && (
              <div className="maskPreview">
                <h3>
                  <span>主题预览</span>
                  <CloseOutlined
                    style={{ color: "rgba(0,0,0,0.4)" }}
                    onClick={() => {
                      setImgUrl("");
                    }}
                  />
                </h3>
                {imgUrl && <img src={imgUrl} />}
              </div>
            )}
            {/* 皮肤选择 */}
            <div
              className="skinMast"
              style={{
                transform: visible ? "translateY(0%)" : "translateY(-100%)",
                transition: "all .5s ease-in .2s",
              }}
            >
              <div className="skinMast-head">
                <img src={require("../static/imgs/skin.png")} />
                <span>主题选择</span>
                <b
                  onClick={() => {
                    setVisible(false);
                  }}
                >
                  {" "}
                  <CaretUpOutlined style={{ fontSize: "0.16rem" }} />
                </b>
              </div>
              <div className="skinMast-body">
                {list.map((item, index) => {
                  return (
                    <div
                      className="skinMast-body-col"
                      style={{
                        background: item.bg,
                        color: item.textColor,
                        border:
                          index == skinInx ? "0.02rem solid #09FEFF" : "none",
                      }}
                      onClick={() => {
                        setSkinInx(index);
                      }}
                      key={index}
                    >
                      <section className="skinMast-body-col-header">
                        <span>{item.text}</span>
                        {index != skinInx && (
                          <span
                            className="skinMast-body-col-preview"
                            onClick={(e) => {
                              _preview(e, index);
                            }}
                          >
                            预览
                          </span>
                        )}
                      </section>
                      {index == skinInx && (
                        <section className="skinMast-body-col-btn">
                          <CheckOutlined
                            style={{ fontSize: "0.12rem", color: "black" }}
                          />
                        </section>
                      )}
                    </div>
                  );
                })}
              </div>
              <div
                className="skinMast-foot"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTheme(theme);
                }}
              >
                <span>确定更换</span>
              </div>
            </div>
            <header>
              {/* <img src={require("../static/imgs/logo.jpg")} /> */}
              <div>模拟展示</div>
              <h2>溧阳工单监控系统</h2>
              <div className="header-right">
                <HeadPark />
                <img
                  onClick={() => {
                    setVisible(true);
                  }}
                  src={require("../static/imgs/skin.png")}
                />
              </div>
            </header>
            <main>{props.content}</main>
            <footer>© 2024-2027 江苏广盛康科技有限公司 版权所有</footer>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default Common;
