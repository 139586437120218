/*
 * @Descripttion: 二级页面--报警
 * @Author: Montana
 * @Date: 2021-08-02 15:30:31
 * @LastEditTime: 2021-08-23 14:39:23
 */
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Pagination } from 'antd'
import { ThemeContext } from '/src/util/context'
import * as echarts from 'echarts';
import './index.scss'
import {
    getAlarmList, todayAlarms, dayCountAlarms, weekCountAlarms,
    monthCountAlarms
} from '/src/util/api'
import store from '/src/store/index'


var myChart
const AlarmPage = (props) => {
    const [fontColor, setFontColor] = useState()
    const [flag, setFlag] = useState(true) // 折线图 > 柱状图 
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(10)
    const [search, setSearch] = useState('')
    const [show, setShow] = useState(false)
    const [obj, setObj] = useState({}) // 柱状图 
    const [data, setData] = useState([]) // 折线图
    const [action, setAction] = useState('day') // 今日 7日 一月
    const [labels, setLabels] = useState([])

    let color = 'white'

    const refresh = () => {
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose();
        }
        setFlag(!flag)
    }

    useEffect(() => {
        const dom = document.getElementById('layout')
        dom.style.minHeight = '100%'
        dom.style.height = 'auto'
        _getAlarmsList()
        _todayAlarms() // 今日报警
        _dayCount() // 分时报警

        store.subscribe(() => {
            setFontColor(store.getState().theme.fontColor)
        })
    }, [])


    // 动作
    useEffect(() => {
        if (action == 'day') {
            _todayAlarms()
            _dayCount()
        } else if (action == 'week') {
            _week()
        } else if (action == 'month') {
            _month()
        }
        _getAlarmsList()
    }, [action])

    useEffect(() => {
        _getAlarmsList()
    }, [page, search, limit])

    // 当日报警
    const _todayAlarms = async () => {
        const res = await todayAlarms()
        if (res.success) {
            setObj(res.result)
        }
    }

    // 分时报警
    const _dayCount = async () => {
        const res = await dayCountAlarms()
        if (res.success) {
            let arr = [
                [1, 2, 3, 4, 5, 6, 7, 8, 9, 1, 2, 2]
            ]
            res.result.map((item1, index1) => {
                arr[index1] = []
                item1.items.map(item2 => {
                    arr[index1].push(item2.count)
                })
            })
            setData(arr)
        }
    }

    // 7日报警
    const _week = async () => {
        const res = await weekCountAlarms()
        if (res.success) {
            let _arr = []
            let _labels = []
            let _obj = {}
            res.result.map((item1, index1) => {
                _arr[index1] = []
                let _count = 0
                item1.items.map(item2 => {
                    _arr[index1].push(item2.count)
                    if (index1 == 0) {
                        _labels.push(item2.time)
                    }
                    _count += item2.count
                    if (item1.type == 'SOS报警') {
                        _obj.sos = _count
                    } else if (item1.type == '进围栏报警') {
                        _obj.fenceIn = _count
                    } else if (item1.type == '出围栏报警') {
                        _obj.fenceOut = _count
                    } else if (item1.type == '电量报警') {
                        _obj.power = _count
                    }
                })
            })
            setLabels(_labels)
            setData(_arr)
            setObj(_obj)
        }
    }

    // 30日报警
    const _month = async () => {
        const res = await monthCountAlarms()
        if (res.success) {
            let _arr = []
            let _labels = []
            let _obj = {}
            res.result.map((item1, index1) => {
                _arr[index1] = []
                let _count = 0
                item1.items.map(item2 => {
                    _arr[index1].push(item2.count)
                    if (index1 == 0) {
                        _labels.push(item2.time.substring(5))
                    }
                    _count += item2.count
                    if (item1.type == 'SOS报警') {
                        _obj.sos = _count
                    } else if (item1.type == '进围栏报警') {
                        _obj.fenceIn = _count
                    } else if (item1.type == '出围栏报警') {
                        _obj.fenceOut = _count
                    } else if (item1.type == '电量报警') {
                        _obj.power = _count
                    }
                })
            })
            setLabels(_labels)
            setData(_arr)
            setObj(_obj)
        }
    }

    // 报警列表
    const _getAlarmsList = async () => {
        let params = {
            search,
            limit,
            page,
            type: action == 'day' ? 1 : (action == 'week' ? 7 : 30)
        }
        const res = await getAlarmList(params)
        if (res.success) {
            setList(
                res.result.data
            )
            setTotal(
                res.result.totalRecord
            )
        }
    }

    useEffect(() => {
        renderChart()
    }, [flag, data, action, obj, fontColor])

    const clearChart = () => {
        if (myChart != null && myChart != "" && myChart != undefined) {
            myChart.dispose();
        }
    }

    const renderChart = () => {
        clearChart()
        if (data.length > 0 && JSON.stringify(obj) != 'obj') {
            if (!flag) {
                // 柱状图
                myChart = echarts.init(document.getElementById('alarm-bar-echarts'));
                myChart.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    xAxis: {
                        type: 'category',
                        data: ['SOS', '进围栏', '出围栏', '电量'],
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: fontColor,  //更改坐标轴文字颜色
                                fontSize: 14      //更改坐标轴文字大小
                            }
                        },
                    },
                    yAxis: {
                        type: 'value',
                        // interval: 1,
                        splitNumber: 2,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: fontColor,  //更改坐标轴文字颜色
                                fontSize: 14      //更改坐标轴文字大小
                            }
                        },
                        splitLine: {    //网格线
                            lineStyle: {
                                color: fontColor,
                                type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                            },
                            show: true //隐藏或显示
                        },
                        axisLine: { show: false },
                        axisTick: { show: false },
                        show: true //隐藏y轴
                    },
                    grid: {
                        left: 20,
                        right: 30,
                        bottom: 30,
                        top: 50,
                        containLabel: true
                    },
                    series: [{
                        barWidth: 170,
                        showBackground: false,
                        data: [{
                            value: obj && obj.sos,
                            itemStyle: {
                                color: '#F40505'
                            }
                        }, {
                            value: obj && obj.fenceIn,
                            itemStyle: {
                                color: '#F59A23'
                            }
                        }, {
                            value: obj && obj.fenceOut,
                            itemStyle: {
                                color: '#2EDC1F'
                            }
                        }, {
                            value: obj && obj.power,
                            itemStyle: {
                                color: '#DB11E3'
                            }
                        }],
                        type: 'bar'
                    }]
                })
            } else {
                myChart = echarts.init(document.getElementById('alarm-line-echarts'));
                myChart.setOption({
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        icon: "circle",
                        data: ['SOS报警', '进围栏报警', '出围栏报警', '电量报警'],
                        textStyle: {
                            color: fontColor
                        },
                        itemGap: 20,
                        orient: 'horizontal',
                        bottom: 20
                    },
                    grid: {
                        left: 20,
                        right: 30,
                        bottom: 70,
                        top: 50,
                        containLabel: true
                    },
                    // toolbox: {
                    //     feature: {
                    //         saveAsImage: {}
                    //     }
                    // },
                    xAxis: {
                        type: 'category',
                        boundaryGap: false,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: fontColor,  //更改坐标轴文字颜色
                                fontSize: action == 'month' ? 12 : 14      //更改坐标轴文字大小
                            }
                        },
                        data:
                            action == 'day' ?
                                ['00:00', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00', '24:00']
                                : labels
                    },
                    yAxis: {
                        type: 'value',
                        // interval: 1,
                        // name: 'Income',
                        splitNumber: 2,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: fontColor,  //更改坐标轴文字颜色
                                fontSize: 14      //更改坐标轴文字大小
                            }
                        },
                        splitLine: {    //网格线
                            lineStyle: {
                                color: fontColor,
                                type: 'dashed'    //设置网格线类型 dotted：虚线   solid:实线
                            },
                            show: true //隐藏或显示
                        }
                    },
                    series: [
                        {
                            symbol: 'circle',
                            symbolSize: 10,
                            lineStyle: {
                                normal: {
                                    width: 4,
                                    color: "#F40505", // 线条颜色
                                },
                            },
                            itemStyle: {
                                color: "#F40505",
                                shadowBlur: 0,
                            },
                            name: 'SOS报警',
                            type: 'line',
                            // stack: '总量',
                            data: data && data[0]
                        },
                        {
                            symbol: 'circle',
                            symbolSize: 10,
                            lineStyle: {
                                normal: {
                                    width: 4,
                                    color: "#2EDC1F", // 线条颜色
                                },
                            },
                            itemStyle: {
                                color: "#2EDC1F",
                                shadowBlur: 0,
                            },
                            name: '进围栏报警',
                            type: 'line',
                            // stack: '总量',
                            data: data && data[1]
                        },
                        {
                            symbol: 'circle',
                            symbolSize: 10,
                            lineStyle: {
                                normal: {
                                    width: 4,
                                    color: "#F59A23", // 线条颜色
                                },
                            },
                            itemStyle: {
                                color: "#F59A23",
                                shadowBlur: 0,
                            },

                            name: '出围栏报警',
                            type: 'line',
                            // stack: '总量',
                            data: data && data[2]
                        },
                        {
                            symbol: 'circle',
                            symbolSize: 10,
                            lineStyle: {
                                normal: {
                                    width: 4,
                                    color: "#DA0EE3", // 线条颜色
                                },
                            },
                            itemStyle: {
                                color: "#DA0EE3",
                                shadowBlur: 0,
                            },
                            name: '电量报警',
                            type: 'line',
                            // stack: '总量',
                            data: data && data[3]
                        }
                    ]
                })
            }
        }
    }

    const renderColor = (str) => {
        switch (str) {
            case 'SOS报警':
                color = '#EA0810'
                break;
            case '出围栏报警':
                color = '#F59A23'
                break;
            case '电量报警':
                color = '#DD15E5'
                break;
            case '进围栏报警':
                color = '#2EDC1F'
                break;
            default:
                color = '#FDFFFD'
                break;
        }
    }


    return (
        <ThemeContext.Consumer>
            {
                theme => {
                    return (
                        <div className='alarm-page' style={{
                            color: theme.fontColor
                        }}>
                            <header>
                                <div>
                                    <span className='icon-layout' onClick={
                                        () => {
                                            props.history.go(-1)
                                        }
                                    } style={{ color: fontColor }}>
                                        <svg className="icon" aria-hidden="true">
                                            <use xlinkHref="#icon-jiantou-copy"></use>
                                        </svg>
                                    </span>
                                    <span style={{
                                        marginLeft: '0.1rem',
                                        fontSize: "0.2rem",
                                        fontWeight: 'bold',
                                        color: fontColor
                                    }}>报警统计</span>
                                </div>

                                <div className="alarm-header-right" style={{ color: fontColor }}>
                                    <span onClick={() => {
                                        setAction('day')
                                        clearChart()
                                    }} style={{
                                        transform: action == 'day' ? 'scale(1.5)' : 'scale(1)',
                                        fontWeight: action == 'day' ? 'bold' : 'normal',
                                        marginRight: action == 'day' ? '0.25rem' : '0.2rem',
                                    }}>今日</span>
                                    <span onClick={() => {
                                        setAction('week')
                                        clearChart()
                                    }} style={{
                                        transform: action == 'week' ? 'scale(1.5)' : 'scale(1)',
                                        fontWeight: action == 'week' ? 'bold' : 'normal',
                                        marginRight: action == 'week' ? '0.2rem' : '0.2rem',
                                    }}>7天</span>
                                    <span onClick={() => {
                                        setAction('month')
                                        clearChart()
                                    }} style={{
                                        transform: action == 'month' ? 'scale(1.5)' : 'scale(1)',
                                        fontWeight: action == 'month' ? 'bold' : 'normal',
                                        // marginRight: action == 'day' ? '0.3rem' : '0.2rem',
                                    }}>30天</span>
                                </div>
                            </header>

                            <div className="alarm-broken-line" >
                                <header style={{
                                    background: theme.subTitle,
                                    color: theme.fontColor
                                }}>
                                    <span>报警数量</span>
                                    <span className='icon-layout' onClick={refresh}>
                                        <svg className="icon" aria-hidden="true">
                                            <use xlinkHref="#icon-shuaxin"></use>
                                        </svg>
                                    </span>
                                </header>

                                <main>
                                    {flag ? <div id='alarm-line-echarts' style={{ height: '4rem' }}></div>
                                        : <div id='alarm-bar-echarts' style={{ height: '4rem' }}></div>}
                                </main>
                            </div>

                            <div className='alarm-list' style={{
                                // background: theme.subTitle,
                                // color: theme.fontColor
                            }}>
                                <section className='alarm-page-list'>
                                    <header style={{
                                        background: theme.subTitle,
                                        color: theme.fontColor
                                    }}>
                                        <span>报警列表</span>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {show && <div style={{
                                                position: "relative", display: 'flex', height: '100%',
                                                alignItems: "center", marginRight: "0.05rem"
                                            }}>
                                                <span style={{
                                                    position: "absolute",
                                                    left: '0.07rem',
                                                }}>
                                                    <svg className="icon" aria-hidden="true" style={{
                                                    }}>
                                                        <use xlinkHref="#icon-search"></use>
                                                    </svg>
                                                </span>
                                                <input onChange={e => {
                                                    setSearch(e.target.value)
                                                }} style={{
                                                    background: '#072438',
                                                    color: fontColor,
                                                    // width:'2rem',
                                                    // height: '0.3rem',
                                                    border: '0.01rem solid #797979',
                                                    fontSize: '0.12rem',
                                                    padding: '0.04rem 0.2rem 0.04rem 0.26rem',
                                                    color: 'white'
                                                }} placeholder='请输入姓名' border='0' /></div>}
                                            {
                                                show ? <span className='icon-layout' onClick={() => {
                                                    setShow(false)
                                                    setSearch('')
                                                }}>
                                                    <svg className="icon" aria-hidden="true">
                                                        <use xlinkHref="#icon-jiantou"></use>
                                                    </svg>
                                                </span> : <span className='icon-layout' onClick={() => {
                                                    setShow(true)
                                                }}>
                                                        <svg className="icon" aria-hidden="true" style={{
                                                            fontSize: '0.16rem'
                                                        }}>
                                                            <use xlinkHref="#icon-search"></use>
                                                        </svg>
                                                    </span>
                                            }
                                            <svg className="icon" aria-hidden="true" style={{
                                                fontSize: '0.16rem',
                                                margin: '0 0.1rem',
                                            }}>
                                                <use xlinkHref="#icon-tubiaozhizuo-"></use>
                                            </svg>
                                            {/* 下载文件 */}
                                            <span className='icon-layout icon-download' onClick={() => {
                                                window.open(`https://www.bdvmp.com/bracelet-wechat-service-kb/kb/alarm_number/export_excel?fileName=alarm&type=${action == 'day' ? 1 : (action == 'week' ? 7 : 30)}`)
                                            }}>
                                                <svg className="icon" aria-hidden="true" style={{
                                                    fontSize: '0.16rem',
                                                }}>
                                                    <use xlinkHref="#icon-download-1-copy"></use>
                                                </svg>
                                            </span>

                                        </div>
                                    </header>
                                    <main>
                                        <table>
                                            <thead>
                                                <tr style={{
                                                    color: theme.fontColor == 'black' ? 'black' : '#00FFFF',
                                                    fontSize: '0.14rem'
                                                }}>
                                                    <th style={{ width: "25%" }}>报警时间</th>
                                                    <th style={{ width: "25%" }}>报警类型</th>
                                                    <th style={{ width: "25%" }}>姓名</th>
                                                    <th style={{ width: "25%" }}>报警地址</th>
                                                    {/* <th style={{ width: "0.8rem" }}>报警</th> */}
                                                </tr>
                                            </thead>

                                            <tbody style={{ color: fontColor }}>
                                                {
                                                    list && list.map(
                                                        (item, index) => {
                                                            renderColor(item.type)
                                                            return <tr key={index}>
                                                                <td>{item.dataTime}</td>
                                                                <td style={{
                                                                    color: color,
                                                                    fontWeight: 'bold'
                                                                }}>{item.type}</td>
                                                                <td>{item.name ? (item.name.substring(0, 5)) : item.braceletId}</td>
                                                                <td>{item.location}</td>
                                                            </tr>

                                                        }
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <hgroup>
                                            <Pagination size="small" total={total} pageSize={limit} pageSizeOptions={[5, 10, 20, 50]} onChange={(page, limit) => {
                                                setPage(page)
                                                setLimit(limit)
                                            }} />
                                        </hgroup>
                                    </main>
                                </section>
                            </div>
                        </div>

                    )
                }
            }
        </ThemeContext.Consumer>
    )
}

export default withRouter(AlarmPage)
