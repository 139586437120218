/*
 * @Descripttion: 
 * @Author: Montana
 * @Date: 2021-08-02 14:45:03
 * @LastEditTime: 2021-08-03 11:16:11
 */
import React, { useEffect } from 'react'
import Common from "/src/page/index";

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";
import { routes } from './config'

function RouteWithSubRoutes(route) {
    return (
        <Route
            path={route.path}
            render={props => {
                return (
                    <Common content={<route.component {...props} routes={route.routes} />} />
                )
            }}
        />
    );
}


const BasicRouter = () => {
    useEffect(() => {
    }, [])

    return (
        <Router>
            <>
                <Switch>
                    {routes.map((route, i) => {
                        return (
                            <RouteWithSubRoutes {...route} key={i} />
                        )
                    })
                    }
                </Switch>
            </>
        </Router>
    )
}

export default BasicRouter