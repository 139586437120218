/*
 * @Descripttion: 微信用户数
 * @Author: Montana
 * @Date: 2021-07-30 13:44:23
 * @LastEditTime: 2021-08-13 13:46:35
 */

import React, { useEffect, useState, useRef } from 'react'
import * as echarts from 'echarts';
import './index.scss'
import { getWechat, setWechat } from '/src/util/api'
import { digitalComma, percent } from '/src/util/tool'
import { Modal, Form, Input, InputNumber, message } from 'antd';
import { ThemeContext } from '/src/util/context'

const WechatUsers = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [obj, setObj] = useState({})
  const [params, setParams] = useState({})
  const [allow, setAllow] = useState(false)

  useEffect(() => {
    _getWechat()
  }, [])

  // 获取访问量
  const _getWechat = async () => {
    const data = await getWechat()
    if (data.success) {
      setObj(data.result)
    }
  }

  useEffect(() => {
    if (params.target && params.password) {
      setAllow(true)
    } else {
      setAllow(false)
    }
  }, [params])

  // 设置微信数
  const _setWechat = async () => {
    if (allow) {
      let _params = {
        target: params.target
      }
      const data = await setWechat(_params)
      if (data.success) {
        message.success('操作成功！')
        _getWechat()
      }
      setIsModalVisible(false);
    }
  }

  useEffect(() => {
    if (JSON.stringify(obj) != '{}') {
      // 基于准备好的dom，初始化echarts实例
      var myChart = echarts.init(document.getElementById('wechart-echarts'));
      myChart.setOption({
        // backgroundColor: '#031d33',
        // tooltip: {
        //   trigger: 'item',
        // },
        grid: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
        xAxis: {
          show: false,
          type: 'value',
          // boundaryGap: [0, 0],
        },
        yAxis: [
          {
            type: 'category',
            data: [''],
            axisLine: { show: false },
            axisTick: [
              {
                show: false,
              },
            ],
          },
        ],
        series: [
          {
            name: '',
            type: 'pictorialBar',
            symbol: require('/src/static/imgs/line.png'),
            symbolSize: [5, 50],
            // symbolOffset: [0, 0],
            z: 0,
            itemStyle: {
              normal: {
                color: '#00FFFF'
              }
            },
            data: [{
              value: (obj.target < obj.allUser) ? (obj.target) : obj.allUser,
              symbolPosition: 'end'
            }]
          },
          {
            name: '微信用户数',
            type: 'bar',
            zlevel: 1,
            barGap: '100%',
            itemStyle: {
              normal: {
                barBorderRadius: 0,
                color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
                  {
                    offset: 1,
                    color: 'rgb(0, 255, 255)',
                  },
                  {
                    offset: 0,
                    color: 'rgba(48, 236, 166, 0.5)',
                  },
                ]),
              },
            },
            barWidth: 20,
            data: [(obj.allUser > obj.target) ? (obj.target) : obj.allUser],
            label: {
              show: obj.allUser > obj.target ? true : false,
              textStyle: {
                color: 'black',  //更改坐标轴文字颜色
                fontSize: 12,    //更改坐标轴文字大小
              },
              formatter: function (params) {
                // let str = params.value != 0 ? params.value + '%' : ''
                let str = '已超过目标值' + (obj.allUser - obj.target)
                return str
              },
            },
          },
          {
            name: '目标数',
            type: 'bar',
            barWidth: 20,
            barGap: '-100%',
            data: [(obj.target > obj.allUser) ? (obj.target) : 0],

            itemStyle: {
              normal: {
                color: 'rgb(255, 255, 255)',
                barBorderRadius: 0,
              },
              barBorderRadius: 4,
            },

          },
        ],
      });
    }
  }, [obj])

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    formRef.current.submit()
    if (params.password == 'shbd') {
      console.log('打印下哈哈哈', params)
      _setWechat()
    } else {
      if (allow) {
        message.error('密码错误')
      }
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const formRef = useRef()

  return (
    <ThemeContext.Consumer>
      {
        theme => {
          return <div className="users-component">
            <Modal title="修改目标" okText='确定' okType={allow ? 'primary' : 'ghost'} cancelText='取消' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
              <Form
                ref={formRef}
                name="basic"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                onValuesChange={(changeVal, allVal) => {
                  setParams(allVal)
                }}
              >
                <Form.Item
                  label="微信用户数目标"
                  name="target"
                  rules={[{ required: true, message: '请输入微信用户数目标!' }]}
                >
                  <InputNumber maxLength={8} style={{ width: '100%' }} />
                </Form.Item>

                <Form.Item
                  label="操作密码"
                  name="password"
                  rules={[{ required: true, message: '请输入操作密码!' }]}
                >
                  <Input.Password />
                </Form.Item>
              </Form>
            </Modal>

            <header style={{
              background: theme.subTitle, color: theme.fontColor
            }}>
              <span>微信用户数</span>
              <span className='icon-layout' onClick={showModal}>
                <svg className="icon" aria-hidden="true">
                  <use xlinkHref="#icon-shezhi"></use>
                </svg>
              </span>
            </header>
            <main style={{ background: theme.subContext, color: theme.fontColor }}>
              <div className='col-1'>
                <span>{obj && digitalComma(obj.allUser)}</span>
                <span>目标: {obj && digitalComma(obj.target)}</span>
              </div>
              <div className='col-2' id="wechart-echarts" style={{ height: "0.3rem", position: 'relative' }}>
              </div>

              <div className='col-3'>
                <div>
                  <span className="col-2-label">月同比</span>
                  <span style={{ margin: '0 0.04rem' }}>
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-sanjiaotop"></use>
                    </svg>
                  </span>
                  <span>{obj.csm && percent(obj.csm)}%</span>
                </div>
                <div>
                  <span className="col-2-label">日环比</span>
                  <span style={{ margin: '0 0.04rem' }}>
                    <svg className="icon" aria-hidden="true">
                      <use xlinkHref="#icon-sanjiaoxia"></use>
                    </svg>
                  </span>
                  <span>{obj.csm && percent(obj.dom)}%</span>
                </div>
              </div>
            </main>
          </div>
        }
      }
    </ThemeContext.Consumer>
  )
}

export default WechatUsers