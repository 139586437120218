let initState = {
    theme:{}
}

const reducer = (state=initState,action) => {
    switch(action.type){
        case 'theme':
            let _state = JSON.parse(JSON.stringify(state))
            _state.theme = action.value
            return _state
    }
    return state
}

export default reducer