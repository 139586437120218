import React, { useEffect, useState, useRef } from "react";
import { Pagination } from "antd";
import { getBracelets, getAllBracelets } from "/src/util/api";
import { ThemeContext } from "/src/util/context";
import store from "/src/store/index";
import "./index.scss";
import * as blueJson from "/src/static/json/blue.json";
import * as whiteJson from "/src/static/json/white.json";
import * as blackJson from "/src/static/json/black.json";
import * as purpleJson from "/src/static/json/purple.json";
import * as greenJson from "/src/static/json/green.json";
import Visit from "./visit"; // 访问数
import WechatUsers from "./user"; // 微信用户数
import UserAge from "./age"; // 用户年龄
import Lastline from "./lastline"; // 昨日在线率
import Alarm from "./alarm"; // 报警数
import Message from "./message"; // 短信报警

const Home = () => {
  const [mapJson, setMapJson] = useState(greenJson);

  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [total, setTotal] = useState(0);
  const [flag, setFlag] = useState(false);
  const [search, setSearch] = useState("");
  const [point, setPoint] = useState({
    longitude: 119.491506,
    latitude: 31.4221,
  });
  const [data, setData] = useState([]);
  const [scale, setScale] = useState(1); // 默认显示大小
  const [center, setCenter] = useState([{ lng: 119.491506 }, { lat: 31.4221 }]);

  let color = "white";
  let imgSrc = "/src/static/imgs/position.png";

  var map = null;
  var pointInit = null;

  useEffect(() => {
    // _getBracelets()
  }, [page, search]);

  useEffect(() => {
    // _getAllBracelets();
    store.subscribe(() => {
      switch (store.getState().theme.bg) {
        case "green":
          setMapJson(greenJson);
          break;
        case "white":
          setMapJson(whiteJson);
          break;
        case "black":
          setMapJson(blackJson);
          break;
        case "purple":
          setMapJson(purpleJson);
          break;
        case "blue":
          setMapJson(blueJson);
          break;
        default:
          break;
      }
    });
  }, []);

  // 移动
  const move = (item) => {
    setCenter(null);
    setScale(15);
    setPoint({
      longitude: item.longitude,
      latitude: item.latitude,
    });
  };

  // 获取所有手环
  const _getAllBracelets = async () => {
    const res = await getAllBracelets();
    if (res.success) {
      setData(res.result);
    }
  };

  // 获取手环列表
  const _getBracelets = async () => {
    let params = {
      page,
      limit,
      search,
    };
    const res = await getBracelets(params);
    if (res.success) {
      setList(res.result.data);
      setTotal(res.result.totalRecord);
    }
  };

  let timer = null;
  useEffect(() => {
    map = new BMap.Map("container", { enableMapClick: false });
    pointInit = center
      ? new BMap.Point(center.lng, center.lat)
      : new BMap.Point(point.longitude, point.latitude);

    // map.setCenter(center);
    map.enableScrollWheelZoom(true);
    map.centerAndZoom(pointInit, scale);
    map.addEventListener("zoomend", function (type, target) {
      setScale(map.getZoom());
    });
    // map.addEventListener("dragging", function () {
    //   // 你的代码
    //   if (timer) {
    //     return;
    //   } else {
    //     timer = setTimeout(() => {
    //       console.log("打印下地图中心点", map.getCenter());
    //       setCenter(map.getCenter());
    //       clearTimeout(timer);
    //       timer = null;
    //     }, 200);
    //   }
    // });
    let top_left_control = new BMap.ScaleControl({
      anchor: BMAP_ANCHOR_TOP_RIGHT,
      offset: new BMap.Size(20, 20),
    }); // 左上角，添加比例尺
    let top_left_navigation = new BMap.NavigationControl(); //左上角，添加默认缩放平移控件
    map.addControl(top_left_control);
    map.addControl(top_left_navigation);
    map.zoomTo(pointInit);
    // var markers = [];
    // var pt = null;
    // data &&
    //   data.map((item) => {
    //     renderColor(item.status);
    //     var labelStyle = {
    //       color: color,
    //       backgroundColor: "rgba(0,0,0,0)",
    //       border: "0",
    //       fontSize: "16px",
    //       width: "80px",
    //       textAlign: "center",
    //     };
    //     let icon = new BMap.Icon(imgSrc, new BMap.Size(200, 200));
    //     icon.setImageSize(new BMap.Size(35, 35));
    //     let label = new BMap.Label(
    //       item.name ? item.name.substring(0, 5) : item.braceletId,
    //       {
    //         offset: item.name
    //           ? new BMap.Size(-22, -22)
    //           : new BMap.Size(-50, -22),
    //       }
    //     );
    //     label.setStyle(labelStyle);
    //     let marker = new BMap.Marker(
    //       new BMap.Point(item.longitude, item.latitude),
    //       {
    //         icon: icon,
    //       }
    //     );
    //     marker.setLabel(label);
    //     markers.push(marker);
    //     new BMapLib.MarkerClusterer(map, { markers: markers });
    //   });
    map.setMapStyleV2({ styleJson: mapJson });
  }, [mapJson]);

  const renderColor = (str) => {
    switch (str) {
      case "SOS报警":
        color = "#EA0810";
        imgSrc = require("../../static/imgs/sos.png");
        break;
      case "出围栏报警":
        color = "#F59A23";
        imgSrc = require("../../static/imgs/out.png");
        break;
      case "电量报警":
        color = "#DD15E5";
        imgSrc = require("../../static/imgs/power.png");
        break;
      case "进围栏报警":
        color = "#2EDC1F";
        imgSrc = require("../../static/imgs/in.png");
        break;
      default:
        color = "#00FFFF";
        imgSrc = require("../../static/imgs/position.png");
        break;
    }
  };

  return (
    <ThemeContext.Consumer>
      {(theme) => {
        return (
          <div>
            <main>
              <section className="map">
                <div className="map-cont"></div>
                <div
                  id="container"
                  style={{ height: "100%", width: "100%", background: "red" }}
                ></div>
              </section>
            </main>
          </div>
        );
      }}
    </ThemeContext.Consumer>
  );
};

export default Home;
