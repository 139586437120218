import React, { useEffect, useState, useRef } from 'react'
import { Modal, Form, Input, InputNumber, message } from 'antd';
import * as echarts from 'echarts';
import './index.scss'
import { getAge, setAge } from '/src/util/api'
import { ThemeContext } from '/src/util/context'
import store from '/src/store/index'

const UserAge = () => {
    const [obj, setObj] = useState({})
    const [fontColor, setFontColor] = useState()
    const [bg, setBg] = useState('')

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [params, setParams] = useState({})
    const [age1, setAge1] = useState({ start: null, end: null })
    const [age2, setAge2] = useState({ start: null, end: null })
    const [age3, setAge3] = useState({ start: null, end: null })
    const [age4, setAge4] = useState({ start: null, end: null })

    const [allow, setAllow] = useState(false)
    const [flag2, setFlag2] = useState(true)
    const [flag3, setFlag3] = useState(true)
    const [flag4, setFlag4] = useState(true)

    useEffect(() => {
        _getAge()
        store.subscribe(() => {
            setFontColor(store.getState().theme.fontColor)
            setBg(store.getState().theme.bg)
        })

    }, [])

    useEffect(() => {
        if (age1.end) {
            setFlag2(false)
        } else {
            setFlag2(true)
            setFlag3(true)
            setFlag4(true)
        }
    }, [age1])

    useEffect(() => {
        if (age2.end) {
            setFlag3(false)
        } else {
            setFlag3(true)
            setFlag4(true)
        }
    }, [age2])

    useEffect(() => {
        if (age3.end) {
            setFlag4(false)
        } else {
            setFlag4(true)
        }
    }, [age3])

    useEffect(() => {
        if (age1.start && age1.end && age2.start && params.password) {
            setAllow(true)
        } else {
            setAllow(false)
        }
    }, [age1, age2, params])

    useEffect(() => {
        if (JSON.stringify(obj) != '{}') {
            var myChart = echarts.init(document.getElementById('age-echarts'));
            let colorArr = []
            obj.age_1 > 0 && colorArr.push('#FFFF37')
            obj.age_2 > 0 && (bg == 'green' ? colorArr.push('#005858') : colorArr.push('#09FEFF'))
            obj.age_3 > 0 && colorArr.push('#F2637B')
            obj.age_4 > 0 && colorArr.push('#09FB10')

            myChart.setOption({
                color: colorArr,
                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `${params.name}  ${params.value}人`
                    },
                    textStyle: {
                        color: fontColor
                    },
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    color: fontColor
                },
                title: {
                    text: '{a|' + '总人数' + '}\n{b|' + `${obj && obj.allUserCount}人` + '}',
                    // text: '{a|' + '总人数' + '}\n{b|' + `782人` + '}',
                    top: '34%',
                    textAlign: "center",
                    left: "47%",
                    textStyle: {
                        rich: {
                            a: {
                                fontSize: 14,
                                color: fontColor,
                                fontWeight: '600',
                            },
                            b: {
                                fontSize: 20,
                                color: fontColor,
                                padding: [5, 0, 14, 0]
                            }
                        }
                    }
                },
                series: [
                    {
                        type: "pie",
                        radius: ["97%", "67%"],
                        center: ["50%", "50%"],
                        hoverAnimation: true,
                        z: 30,
                        itemStyle: {
                            normal: {
                                borderWidth: 0,
                                borderColor: "#1C42AA"
                            }
                        },
                        label: {
                            show: false
                        },
                        data: [{
                            value: obj && obj.age_1,
                            name: obj && obj.age_1_memo
                        }, {
                            value: obj && obj.age_2,
                            // value: 123,
                            name: obj && obj.age_2_memo
                        }, (obj.age_3_memo && {
                            // value: obj && obj.age_56_90,
                            value: obj && obj.age_3,
                            // value: 576,
                            name: obj && obj.age_3_memo
                        }), (obj.age_4_memo && {
                            value: obj && obj.age_4,
                            // value: 33,
                            name: obj && obj.age_4_memo
                        })],
                        labelLine: {
                            show: false
                        }
                    }
                ]
            });
        }
    }, [obj, fontColor, bg])

    // 获取访问量
    const _getAge = async () => {
        const data = await getAge()
        if (data.success) {
            setObj(data.result)
            console.log('获取手环年龄', data.result)
        }
    }

    // 设置年龄
    const _setAge = async () => {
        let flag = true;
        let arr = [];
        if (age1.start != null && age1.end && age2.start) {
            flag = true
        } else {
            flag = false
            message.error('至少输入两组年龄')
            return
        }
        if (age1.start && age1.end) {
            if (age1.end < age1.start) {
                message.error('输入范围错误')
                flag = false
            }
            arr.push({
                "ageEnd": age1.end, //截止年龄
                "ageStart": age1.start, //开始年龄
                "sort": 1
            })
        }
        if (age2.start) {
            if (age2.end) {
                if (age2.end < age2.start) {
                    message.error('输入范围错误')
                    flag = false
                }
            }
            if (age2.start <= age1.end) {
                flag = false
            }
            arr.push({
                "ageEnd": age2.end, //截止年龄
                "ageStart": age2.start, //开始年龄
                "sort": 2
            })
        }
        if (age3.start) {
            if (age3.end) {
                if (age3.end < age3.start) {
                    message.error('输入范围错误')
                    flag = false
                }
            }
            if (age3.start <= age2.end) {
                flag = false
            }
            arr.push({
                "ageEnd": age3.end, //截止年龄
                "ageStart": age3.start, //开始年龄
                "sort": 3
            })
        }
        if (age4.start) {
            if (age4.end) {
                if (age4.end < age4.start) {
                    message.error('输入范围错误')
                    flag = false
                }
            }
            if (age4.start <= age3.end) {
                flag = false
            }
            arr.push({
                "ageEnd": age4.end, //截止年龄
                "ageStart": age4.start, //开始年龄
                "sort": 4
            })
        }


        if (flag) {
            const data = await setAge(arr)
            if (data.success) {
                message.success('设置成功')
                setIsModalVisible(false)
                _getAge()
            } else {
                message.error('设置失败')
            }
        } else {
            message.error('输入格式不正确')
        }
    }

    const formRef = useRef()

    const handleOk = () => {
        // formRef.current.submit()
        if (allow) {
            if (params.password == 'shbd') {
                _setAge()
            } else {
                message.error('密码错误')
            }
        } else {
            message.warn('请核对输入项及密码')
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <ThemeContext.Consumer>
            {
                theme => {
                    return <div className="age-component">
                        <Modal title={
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <span>修改用户年龄段</span>
                                <span style={{ fontSize: '0.12rem', marginLeft: "0.1rem", color: 'rgba(0,0,0,0.5)' }}>至少填写2个年龄段，其他不填写即视为无</span>
                            </div>
                        } okText='确定' okType={allow ? 'primary' : 'ghost'} cancelText='取消' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                            <Form
                                ref={formRef}
                                name="basic"
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onValuesChange={(changeVal, allVal) => {
                                    setParams(allVal)
                                }}
                            >
                                <Form.Item
                                    label="年龄段1"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setAge1(
                                                {
                                                    "start": e,
                                                    "end": age1.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setAge1(
                                                {
                                                    "start": age1.start,
                                                    "end": e
                                                }
                                            )

                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="年龄段2"
                                    name="target"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setAge2(
                                                {
                                                    "start": e,
                                                    "end": age2.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag2} />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setAge2(
                                                {
                                                    "start": age2.start,
                                                    "end": e
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag2} />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="年龄段3"
                                    name="target"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setAge3(
                                                {
                                                    "start": e,
                                                    "end": age3.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag3} />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setAge3(
                                                {
                                                    "start": age3.start,
                                                    "end": e
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag3} />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="年龄段4"
                                    name="target"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setAge4(
                                                {
                                                    "start": e,
                                                    "end": age4.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag4} />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setAge4(
                                                {
                                                    "start": age4.start,
                                                    "end": e
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag4} />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    label="操作密码"
                                    name="password"
                                // rules={[{ required: true, message: '请输入操作密码!' }]}
                                >
                                    <Input.Password style={{ width: '40%' }} />
                                </Form.Item>
                            </Form>
                        </Modal>

                        <header style={{
                            background: theme.subTitle,
                            color: theme.fontColor
                        }}>
                            <span>用户年龄</span>
                            <span className='icon-layout' onClick={() => { setIsModalVisible(true) }}>
                                <svg className="icon" aria-hidden="true">
                                    <use xlinkHref="#icon-shezhi"></use>
                                </svg>
                            </span>
                        </header>
                        <main style={{
                            background: theme.subContext,
                            color: theme.fontColor
                        }}>
                            <div style={{ flex: 1, position: 'relative', display: 'flex' }}>
                                <div id="age-echarts" style={{ height: '95%', width: '100%', color: theme.fontColor }}>
                                </div>
                            </div>
                            <div className='col-3' style={{
                                height: obj.age_3_memo ? '0.24rem' : '0.48rem'
                            }}>
                                <div>
                                    <div className="circle" style={{ background: '#FFFF37' }}></div>
                                    <span className="col-2-label">{obj && obj.age_1_memo}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    {/* <span>{obj && obj.age_5_18 * 50}人</span> */}
                                    <span style={{ alignSelf: 'flex-end' }}>{obj && obj.age_1}人</span>
                                </div>
                                <div>
                                    <div className="circle" style={{ background: bg == 'green' ? '#005858' : '#09FEFF' }}></div>
                                    <span className="col-2-label">{obj && obj.age_2_memo}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span style={{ alignSelf: 'flex-end' }}>{obj && obj.age_2}人</span>
                                    {/* <span style={{ alignSelf: 'flex-end' }}>576人</span> */}
                                </div>
                            </div>

                            {obj.age_3_memo && <div className='col-3'>
                                <div>
                                    <div className="circle" style={{ background: '#F2637B' }}></div>
                                    <span className="col-2-label">{obj && obj.age_3_memo}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span>{obj && obj.age_3}人</span>
                                    {/* <span>123人</span> */}
                                </div>

                                {obj.age_4_memo && <div>
                                    <div className="circle" style={{ background: '#09FB10' }}></div>
                                    <span className="col-2-label">{obj && obj.age_4_memo}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span>{obj && obj.age_4}人</span>
                                    {/* <span>33人</span> */}
                                </div>}
                            </div>}
                        </main>
                    </div>
                }
            }
        </ThemeContext.Consumer>
    )
}

export default UserAge

