/*
 * @Descripttion: 工具方法 
 * @Author: Montana
 * @Date: 2021-08-03 10:18:38
 * @LastEditTime: 2021-08-03 12:54:19
 */

export const digitalComma = (num) => {
    if (!num) {
        num = 0
    }
    num = num.toString()
    if (num.length > 3 && num.length <= 6) {
        let _num = num.split('')
        _num = _num.reverse()
        _num.splice(3, 0, ',')
        _num = _num.reverse()
        _num = _num.join('')
        num = _num
    } else if (num.length > 6 && num.length <= 9) {
        let _num = num.split('')
        _num = _num.reverse()
        _num.splice(3, 0, ',')
        _num.splice(7, 0, ',')
        _num = _num.reverse()
        _num = _num.join('')
        num = _num
    } else if (num.length > 9 && num.length <= 12) {
        let _num = num.split('')
        _num = _num.reverse()
        _num.splice(3, 0, ',')
        _num.splice(7, 0, ',')
        _num.splice(11, 0, ',')
        _num = _num.reverse()
        _num = _num.join('')
        num = _num
    }
    return num
}

export const percent = (num) => {
    if (!num) {
        num = 0
    }
    num = num.toString()
    if (num.indexOf('.') != -1) {
        num = num * 1
        num = num.toFixed(2)
    } else {
        // 没有小数点
        num = num * 1
        num = Math.ceil(num)
    }
    return num
}