import React, { useEffect, useState, useRef } from 'react'
import { Modal, Form, Input, InputNumber, message } from 'antd';
import * as echarts from 'echarts';
import './index.scss'
import { getMessage, setMessage } from '/src/util/api'
import { ThemeContext } from '/src/util/context'
import store from '/src/store/index'

const Message = () => {
    const [obj, setObj] = useState({})
    const [fontColor, setFontColor] = useState()
    const [bg,setBg] = useState()
    
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [params, setParams] = useState({})
    const [msg1, setMsg1] = useState({ start: null, end: null })
    const [msg2, setMsg2] = useState({ start: null, end: null })
    const [msg3, setMsg3] = useState({ start: null, end: null })
    const [msg4, setMsg4] = useState({ start: null, end: null })

    const [allow, setAllow] = useState(false)
    const [flag2, setFlag2] = useState(true)
    const [flag3, setFlag3] = useState(true)
    const [flag4, setFlag4] = useState(true)

    useEffect(() => {
        _getMessage()
        store.subscribe(() => {
            setFontColor(store.getState().theme.fontColor)
            setBg(store.getState().theme.bg)
        })
    }, [])

    const _getMessage = async () => {
        const data = await getMessage()
        if (data.success) {
            setObj(data.result)
        }
    }

    useEffect(() => {
        if (JSON.stringify(obj) != '{}') {
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('message-echarts'));
            let colorArr = []
            obj.time_1 >=0 && colorArr.push('#FFFF37')
            obj.time_2 >=0 && (bg == 'green' ? colorArr.push('#005858') : colorArr.push('#09FEFF'))
            obj.time_3 >=0 && colorArr.push('#F2637B')
            obj.time_4 >=0 && colorArr.push('#09FB10')

            myChart.setOption({
                color: colorArr,

                tooltip: {
                    trigger: 'item',
                    formatter: function (params) {
                        return `${params.name}  ${params.value}次`
                    },
                    textStyle: {
                        color: fontColor
                    },
                    backgroundColor: 'rgba(0,0,0,0.4)',
                    color: fontColor
                },
                title: {
                    text: '{a|' + '总计' + '}\n{b|' + `${obj && obj.allCount}次` + '}',
                    // text: '{a|' + '总计' + '}\n{b|' + `110次` + '}',
                    top: '34%',
                    textAlign: "center",
                    left: "47%",
                    textStyle: {
                        rich: {
                            a: {
                                fontSize: 14,
                                color: fontColor,
                                fontWeight: '600',
                            },
                            b: {
                                fontSize: 20,
                                color: fontColor,
                                padding: [5, 0, 14, 0]
                            }
                        }
                    }
                },
                series: [
                    {
                        type: "pie",
                        radius: ["97%", "67%"],
                        center: ["50%", "50%"],
                        hoverAnimation: true,
                        z: 30,
                        itemStyle: {
                            normal: {
                                borderWidth: 0,
                                borderColor: "#1C42AA"
                            }
                        },
                        label: {
                            show: false
                        },
                        data: [{
                            value: obj && obj.time_1,
                            // value: 12,
                            name: obj && obj.time_memo_1
                        }, {
                            value: obj && obj.time_2,
                            // value: 40,
                            name: obj && obj.time_memo_2
                        }, (obj.time_memo_3 && {
                            value: obj && obj.time_3,
                            // value: 32,
                            name: obj && obj.time_memo_3
                        }), (obj.time_memo_4 && {
                            value: obj && obj.time_4,
                            // value: 21,
                            name: obj && obj.time_memo_4
                        })],
                        labelLine: {
                            show: false
                        }
                    }
                ]
            });
        }
    }, [obj, fontColor,bg])

    useEffect(() => {
        if (msg1.end) {
            setFlag2(false)
        } else {
            setFlag2(true)
            setFlag3(true)
            setFlag4(true)
        }
    }, [msg1])

    useEffect(() => {
        if (msg2.end) {
            setFlag3(false)
        } else {
            setFlag3(true)
            setFlag4(true)
        }
    }, [msg2])

    useEffect(() => {
        if (msg3.end) {
            setFlag4(false)
        } else {
            setFlag4(true)
        }
    }, [msg3])

    useEffect(() => {
        if (msg1.start != null && msg1.end && msg2.start && params.password) {
            setAllow(true)
        } else {
            setAllow(false)
        }
    }, [msg1, msg2, params])

    // 设置年龄
    const _setMsg = async () => {
        let flag = true;
        let arr = [];
        if (msg1.start != null && msg1.end && msg2.start) {
            flag = true
        } else {
            flag = false
            message.error('至少输入两组年龄')
            return
        }
        if (msg1.start != null && msg1.end) {
            if (msg1.end < msg1.start) {
                message.error('输入范围错误')
                flag = false
            }
            arr.push({
                "timeEnd": msg1.end, //截止年龄
                "timeStart": msg1.start, //开始年龄
                "sort": 1
            })
        }
        if (msg2.start) {
            if (msg2.end) {
                if (msg2.end < msg2.start) {
                    message.error('输入范围错误')
                    flag = false
                }
            }
            if (msg2.start <= msg1.end) {
                flag = false
            }
            arr.push({
                "timeEnd": msg2.end, //截止年龄
                "timeStart": msg2.start, //开始年龄
                "sort": 2
            })
        }
        if (msg3.start) {
            if (msg3.end) {
                if (msg3.end < msg3.start) {
                    message.error('输入范围错误')
                    flag = false
                }
            }
            if (msg3.start <= msg2.end) {
                flag = false
            }
            arr.push({
                "timeEnd": msg3.end, //截止年龄
                "timeStart": msg3.start, //开始年龄
                "sort": 3
            })
        }
        if (msg4.start) {
            if (msg4.end) {
                if (msg4.end < msg4.start) {
                    message.error('输入范围错误')
                    flag = false
                }
            }
            if (msg4.start <= msg3.end) {
                flag = false
            }
            arr.push({
                "timeEnd": msg4.end, //截止年龄
                "timeStart": msg4.start, //开始年龄
                "sort": 4
            })
        }

        if (flag) {
            const data = await setMessage(arr)
            if (data.success) {
                message.success('设置成功')
                setIsModalVisible(false)
                _getMessage()
            } else {
                message.error('设置失败')
            }
        } else {
            message.error('输入格式不正确')
        }
    }


    const handleOk = () => {
        // formRef.current.submit()
        if (allow) {
            if (params.password == 'shbd') {
                _setMsg()
            } else {
                message.error('密码错误')
            }
        } else {
            message.warn('请核对输入项及密码')
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const formRef = useRef()

    return (
        <ThemeContext.Consumer>
            {
                theme => {
                    return <div className="message-component">
                        <Modal title={
                            <div style={{ display: "flex", alignItems: 'center' }}>
                                <span>修改时间段</span>
                                <span style={{ fontSize: '0.12rem', marginLeft: "0.1rem", color: 'rgba(0,0,0,0.5)' }}>至少填写2个时间段，其他不填写即视为无</span>
                            </div>
                        } okText='确定' okType={allow ? 'primary' : 'ghost'} cancelText='取消' visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
                            <Form
                                ref={formRef}
                                name="basic"
                                labelCol={{ span: 6 }}
                                wrapperCol={{ span: 16 }}
                                initialValues={{ remember: true }}
                                onValuesChange={(changeVal, allVal) => {
                                    setParams(allVal)
                                }}
                            >
                                <Form.Item
                                    label="时间段1"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg1(
                                                {
                                                    "start": e,
                                                    "end": msg1.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg1(
                                                {
                                                    "start": msg1.start,
                                                    "end": e
                                                }
                                            )

                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="时间段2"
                                    name="target"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg2(
                                                {
                                                    "start": e,
                                                    "end": msg2.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag2} />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg2(
                                                {
                                                    "start": msg2.start,
                                                    "end": e
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag2} />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="时间段3"
                                    name="target"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg3(
                                                {
                                                    "start": e,
                                                    "end": msg3.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag3} />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg3(
                                                {
                                                    "start": msg3.start,
                                                    "end": e
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag3} />
                                    </div>
                                </Form.Item>
                                <Form.Item
                                    label="时间段4"
                                    name="target"
                                >
                                    <div>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg4(
                                                {
                                                    "start": e,
                                                    "end": msg4.end
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag4} />
                                        <span style={{ margin: '0 0.1rem' }}>~</span>
                                        <InputNumber min={0} onChange={e => {
                                            setMsg4(
                                                {
                                                    "start": msg4.start,
                                                    "end": e
                                                }
                                            )
                                        }} maxLength={2} style={{ width: '40%' }} placeholder='请输入2位以内纯数字' disabled={flag4} />
                                    </div>
                                </Form.Item>

                                <Form.Item
                                    label="操作密码"
                                    name="password"
                                // rules={[{ required: true, message: '请输入操作密码!' }]}
                                >
                                    <Input.Password style={{ width: '40%' }} />
                                </Form.Item>
                            </Form>
                        </Modal>

                        <header style={{
                            background: theme.subTitle, color: theme.fontColor
                        }}>
                            <span>短信报警</span>
                            <span className='icon-layout' onClick={() => { setIsModalVisible(true) }}>
                                <svg className="icon" aria-hidden="true">
                                    <use xlinkHref="#icon-shezhi"></use>
                                </svg>
                            </span>
                        </header>
                        <main style={{ background: theme.subContext, color: theme.fontColor }}>
                            <div style={{ flex: 1, position: 'relative', display: 'flex' }}>
                                <div id="message-echarts" style={{ height: '95%', width: '100%' }}>
                                </div>
                            </div>
                            <div className='col-3' style={{
                                height: obj.time_memo_3 ? '0.24rem' : '0.48rem'
                            }}>
                                <div>
                                    <div className="circle" style={{ background: '#FFFF37' }}></div>
                                    <span className="col-2-label">{obj && obj.time_memo_1}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span>{obj && obj.time_1}次</span>
                                </div>
                                <div>
                                    <div className="circle" style={{  background: bg == 'green' ? '#005858' : '#09FEFF' }}></div>
                                    <span className="col-2-label">{obj && obj.time_memo_2}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span>{obj && obj.time_2}次</span>                                    {/* <span style={{ alignSelf: 'flex-end' }}>32次</span> */}
                                </div>
                            </div>

                            {obj.time_memo_3 && <div className='col-3'>
                                <div>
                                    <div className="circle" style={{ background: '#F2637B' }}></div>
                                    <span className="col-2-label">{obj && obj.time_memo_3}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span>{obj && obj.time_3}次</span>
                                </div>

                                <div>
                                    <div className="circle" style={{ background: '#09FB10' }}></div>
                                    <span className="col-2-label">{obj && obj.time_memo_4}</span>
                                    <span style={{ margin: '0 0.04rem' }}>
                                        |
                                </span>
                                    <span>{obj && obj.time_4}次</span>
                                </div>
                            </div>}
                        </main>
                    </div>
                }
            }
        </ThemeContext.Consumer>
    )
}

export default Message

