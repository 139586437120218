/*
 * @Descripttion: 
 * @Author: Montana
 * @Date: 2021-07-30 09:49:55
 * @LastEditTime: 2021-08-06 22:24:57
 */
function font() {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 12.8 + 'px'
}

function scale() {
    document.documentElement.style.fontSize = document.documentElement.clientWidth / 12.8 + 'px'
    // 　location.reload();
}

font()

window.onresize = scale