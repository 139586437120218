import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts';
import './index.scss'
import { getOnline } from '/src/util/api'
import { ThemeContext } from '/src/util/context'
import store from '/src/store/index'

const Lastline = () => {
    const [obj, setObj] = useState({})
    const [fontColor, setFontColor] = useState()

    useEffect(() => {
        _getOnline()
        store.subscribe(() => {
            setFontColor(store.getState().theme.fontColor)
        })
    }, [])

    useEffect(() => {
        if (JSON.stringify(obj) != '{}') {
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('lastline-echarts'));
            myChart.setOption({
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'shadow'
                    },
                    formatter(params) {
                        let str = params[0].value != 0 ? params[0].value + '%' : ''
                        return str
                    }
                },
                grid: {
                    left: 10,
                    right: 10,
                    top: 0,
                    bottom: -20,
                    containLabel: true
                },
                xAxis: {
                    type: 'value',
                    // boundaryGap: [0, 0.01],
                    axisLine: { show: false },
                    axisTick: { show: false },
                    show: false//隐藏y轴
                },
                yAxis: {
                    type: 'category',
                    data: ['7点', '12点', '17点', '22点'],
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: fontColor,  //更改坐标轴文字颜色
                            fontSize: 8,     //更改坐标轴文字大小
                        }
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    show: true//隐藏y轴
                },
                series:
                    [{
                        type: 'bar',
                        data: [36, 23, 56, 36],
                        // data: [Math.round((obj.t7 / obj.t7All) * 100), Math.round((obj.t12 / obj.t12All) * 100),
                        // Math.round((obj.t17 / obj.t17All) * 100), Math.round((obj.t22 / obj.t22All) * 100)],
                        barWidth: 15,
                        label: {
                            position: 'right',
                            show: true,
                            textStyle: {
                                color: fontColor,  //更改坐标轴文字颜色
                                fontSize: 8,    //更改坐标轴文字大小
                            },
                            formatter: function (params) {
                                let str = params.value != 0 ? params.value + '%' : ''
                                return str
                            },
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: {
                            normal: {
                                // barBorderRadius: [0,30,30,0],
                                color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [{
                                    offset: 0,
                                    color: '#52d8da'
                                }, {
                                    offset: 1,
                                    color: '#57dcde'
                                }]),
                                shadowBlur: 0,
                                shadowColor: 'rgba(87,220,222,0.7)'
                            },
                        }
                    }]
            })
        }
    }, [obj,fontColor])

    // 获取访问量
    const _getOnline = async () => {
        const data = await getOnline()
        if (data.success) {
            setObj(data.result)
        }
    }

    return (
        <ThemeContext.Consumer>
            {
                theme => {
                    return <div className="online-component">
                        <header style={{ background: theme.subTitle, color: theme.fontColor }}>
                            <span>昨日在线率</span>
                            {/* <svg className="icon" aria-hidden="true">
                                <use xlinkHref="#icon-shezhi"></use>
                            </svg> */}
                        </header>
                        <main style={{ background: theme.subContext, color: theme.fontColor }}>
                            <div id="lastline-echarts" style={{ height: "95%", width: '96%' }}>
                            </div>
                        </main>
                    </div>
                }
            }
        </ThemeContext.Consumer>
    )
}

export default Lastline