import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts';
import './index.scss'
import { withRouter } from 'react-router-dom'
import { getAlarm } from '/src/util/api'
import { ThemeContext } from '/src/util/context'
import store from '/src/store/index'

const Alarm = (props) => {
    const [obj, setObj] = useState({})
    const [fontColor, setFontColor] = useState()

    useEffect(() => {
        _getAlarm()
        store.subscribe(() => {
            setFontColor(store.getState().theme.fontColor)
        })
    }, [])

    // 获取报警数
    const _getAlarm = async () => {
        const data = await getAlarm()
        if (data.success) {
            setObj(data.result)
        }
    }

    useEffect(() => {
        if (JSON.stringify(obj) != '{}') {
            // 基于准备好的dom，初始化echarts实例
            var myChart = echarts.init(document.getElementById('alarm-echarts'));
            myChart.setOption({
                tooltip: {
                    trigger: 'axis'
                },
                xAxis: {
                    type: 'category',
                    data: ['SOS', '出围栏', '进围栏', '电量'],
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: fontColor,  //更改坐标轴文字颜色
                            fontSize: 8      //更改坐标轴文字大小
                        }
                    },
                },
                yAxis: {
                    type: 'value',
                    splitLine: { show: false },//去除网格线
                    // interval: 1,
                    splitNumber: 2,
                    axisLabel: {
                        show: true,
                        textStyle: {
                            color: fontColor,  //更改坐标轴文字颜色
                            fontSize: 8      //更改坐标轴文字大小
                        }
                    },
                    axisLine: { show: false },
                    axisTick: { show: false },
                    show: true//隐藏y轴
                },
                grid: {
                    top: 10,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    containLabel: true
                },
                series: [{
                    barWidth: '15',
                    showBackground: false,
                    data: [{
                        value: obj && obj.sos,
                        // value: 33,
                        itemStyle: {
                            color: '#F40505'
                        }
                    }, {
                        value: obj && obj.fenceOut,
                        // value: 45,
                        itemStyle: {
                            color: '#F59A23'
                        }
                    }, {
                        value: obj && obj.fenceIn,
                        // value: 36,
                        itemStyle: {
                            color: '#2EDC1F'
                        }
                    }, {
                        value: obj && obj.power,
                        // value: 67,
                        itemStyle: {
                            color: '#DB11E3'
                        }
                    }],
                    type: 'bar'
                }]
            })
        }
    }, [obj, fontColor])
    
    return (
        <ThemeContext.Consumer>
            {
                theme => {
                    return <div className="alarm-component">
                        <header style={{ background: theme.subTitle, color: theme.fontColor }}>
                            <span>报警数</span>
                            <span className='icon-layout' onClick={
                                () => {
                                    props.history.push(
                                        '/bracelet-wechat-service-kb/alarm'
                                    )
                                }
                            }>
                                <svg className="icon" aria-hidden="true">
                                    <use xlinkHref="#icon-arrow-right-copy-copy"></use>
                                </svg>
                            </span>

                        </header>
                        <main style={{ background: theme.subContext, color: theme.fontColor }}>
                            <div id="alarm-echarts" style={{ height: "90%", width: '90%' }}>
                            </div>
                        </main>
                    </div>
                }
            }
        </ThemeContext.Consumer>
    )
}

export default withRouter(Alarm)