/*
 * @Descripttion: 路由配置
 * @Author: Montana
 * @Date: 2021-08-02 14:54:05
 * @LastEditTime: 2021-08-06 18:14:51
 */
import Home from '/src/page/home/index'
import AlarmPage from '/src/page/alarm/index'

export const routes = [
    {
        path: "/index.html",
        component: Home,
        exact: true
    },
    {
        path: "/bracelet-wechat-service-kb",
        component: Home,
        exact: true
    },
    {
        path: "/",
        component: Home,
        exact: true
    },
    {
        path: "/alarm",
        component: AlarmPage,
        exact: true
    },
    {
        path: "/bracelet-wechat-service-kb/alarm",
        component: AlarmPage,
        exact: true
    }
]