/*
 * @Descripttion: 接口文档
 * @Author: Montana
 * @Date: 2021-08-03 09:47:28
 * @LastEditTime: 2021-08-04 21:24:27
 */
import { message } from 'antd'
const proxy_url = 'https://www.bdvmp.com'
var myHeaders = new Headers()
myHeaders.append('Content-Type', 'application/json')
// 处理参数
export const defineGetParams = (url, params) => {
    if (params) {
        let paramsArray = [];
        //拼接参数
        Object.keys(params).forEach(key => paramsArray.push(key + '=' + params[key]))
        if (url.search(/\?/) === -1) {
            url += '?' + paramsArray.join('&')
        } else {
            url += '&' + paramsArray.join('&')
        }
    }
    return url
}


// fetch封装
const fetchGadget = (url, method, params = null, body = null) => {
    let _url = `${proxy_url}${url}`
    if (params) {
        _url = defineGetParams(_url, params)
    }

    // loading加载
    // store.dispatch({
    //     type: 'LOADING_START'
    // })

    return fetch(_url, {
        method: method,
        headers: myHeaders,
        body: body ? JSON.stringify(body) : null
    }).then(res => {
        // store.dispatch({
        //     type: "LOADING_END"
        // })
        return res.json()
    }).then(res => {
        if (res.code != 2000) {
            message.error(res.msg)
        }
        return res
    })
}

// 访问量
export const getVisitor = () => {
    const url = '/bracelet-wechat-service-kb/kb/pv/get_pv_info'
    return fetchGadget(url, 'GET')
}

// 设置访问量
export const setVisitor = (params) => {
    const url = '/bracelet-wechat-service-kb/kb/pv/set_pv_target'
    return fetchGadget(url, 'POST', params)
}

// 微信数
export const getWechat = () => {
    const url = '/bracelet-wechat-service-kb/kb/wechat/get_wechat_info'
    return fetchGadget(url, 'GET')
}

// 设置微信用户数
export const setWechat = (params) => {
    const url = '/bracelet-wechat-service-kb/kb/wechat/set_wechat_target'
    return fetchGadget(url, 'POST', params)
}

// 年龄
export const getAge = () => {
    const url = '/bracelet-wechat-service-kb/kb/user_age/get_user_age_info'
    return fetchGadget(url, 'GET')
}

// 设置年龄
export const setAge = (params) => {
    const url = '/bracelet-wechat-service-kb/kb/user_age/save_age_info'
    return fetchGadget(url, 'POST', null, params)
}

// 昨日在线率
export const getOnline = () => {
    const url = '/bracelet-wechat-service-kb/kb/online_rate/get_online_rate_info'
    return fetchGadget(url, 'GET')
}

// 报警数
export const getAlarm = () => {
    const url = '/bracelet-wechat-service-kb/kb/alarm_number/get_alarm_number_info'
    return fetchGadget(url, 'GET')
}

// 短信报警
export const getMessage = () => {
    const url = '/bracelet-wechat-service-kb/kb/sms_alarm/get_sms_alarm_info'
    return fetchGadget(url, 'GET')
}

// 设置短信报警
export const setMessage = (params) => {
    const url = '/bracelet-wechat-service-kb/kb/sms_alarm/save_alarm_time'
    return fetchGadget(url, 'POST', null, params)
}


// 手环列表
export const getBracelets = (params) => {
    const url = '/bracelet-wechat-service-kb/kb/bracelt/get_bracelt_list'
    return fetchGadget(url, 'GET', params)
}

// 报警列表
export const getAlarmList = (params) => {
    const url = '/bracelet-wechat-service-kb/kb/alarm_number/get_alarm_list'
    return fetchGadget(url, 'GET', params)
}

// 当日告警数 
export const todayAlarms = () => {
    const url = '/bracelet-wechat-service-kb/kb/alarm_number/get_alarm_number_info'
    return fetchGadget(url, 'GET')
}

// 当日报警分时 折线图
export const dayCountAlarms = () => {
    const url = '/bracelet-wechat-service-kb/kb/alarm_number/get_alarm_today_count'
    return fetchGadget(url, 'GET')
}

// 7日报警数 折线图 
export const weekCountAlarms = () => {
    const url = '/bracelet-wechat-service-kb/kb/alarm_number/get_alarm_7_count'
    return fetchGadget(url, 'GET')
}

// 30日报警数 折线图 
export const monthCountAlarms = () => {
    const url = '/bracelet-wechat-service-kb/kb/alarm_number/get_alarm_30_count'
    return fetchGadget(url, 'GET')
}

// 获取所有手环
export const getAllBracelets = () => {
    const url = '/bracelet-wechat-service-kb/kb/bracelt/get_all_bracelt'
    return fetchGadget(url, 'GET')
}